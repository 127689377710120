var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.appConfig.rol != 0)?_c('b-nav-item-dropdown',{staticClass:"dropdown-language",attrs:{"id":"dropdown-grouped","variant":"link","left":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"ml-50 text-body color-text-producto-dropdown"},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.appConfig.projecto.name))])]),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"ChevronDownIcon","size":"14"}})]},proxy:true}],null,false,808536570)},[_c('div',{staticClass:"drop-scrolling"},[_c('b-form-input',{staticClass:"mx-auto search-proyect",attrs:{"slot":"left","type":"search","autocomplete":"off","placeholder":"Busca un producto"},on:{"input":_vm.buscaProducto},slot:"left",model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_vm._l((_vm.$store.state.appConfig.proyectos.filter(function (x){
         var nameSearch = x.name.toUpperCase()
         var texto = _vm.searchText.toUpperCase()
         if(nameSearch.includes(texto)){
           return x
         }
         })),function(proyecto,i){return _c('b-dropdown-item',{key:proyecto + i,on:{"click":function () {
           _vm.handleChangeStore(proyecto);
         }}},[_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(proyecto.name))])])})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }