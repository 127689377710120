<template>
  <b-nav-item-dropdown
    v-if="$store.state.appConfig.rol != 0"
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    left
  >
    <template #button-content>
      <span class="ml-50 text-body color-text-producto-dropdown">
        <strong>{{ $store.state.appConfig.projecto.name }}</strong>
      </span>
      <feather-icon class="ml-1" icon="ChevronDownIcon" size="14">
      </feather-icon>
    </template>
    <div class="drop-scrolling">
      <b-form-input slot="left" type="search" class="mx-auto search-proyect" @input="buscaProducto" autocomplete="off"
 v-model="searchText" placeholder="Busca un producto"></b-form-input>
      <b-dropdown-item
        v-for="(proyecto, i) in $store.state.appConfig.proyectos.filter((x)=> {
          let nameSearch = x.name.toUpperCase()
          let texto = searchText.toUpperCase()
          if(nameSearch.includes(texto)){
            return x
          }
          })"
        :key="proyecto + i"
        @click="
          () => {
            handleChangeStore(proyecto);
          }
        "
      >
        <span class="ml-50">{{ proyecto.name }}</span>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg, BFormInput } from 'bootstrap-vue';
import axios from 'axios';
import { getUserData } from '@/auth/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { backendUrl } from '@/config';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BFormInput
  },
  methods: {
    buscaProducto(){
      this.proyectos.filter((x)=> x == this.searchText)
      
    },
    showProductNotFoundToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error al cargar Producto',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },
    handleChangeStore(pro) {
      this.$store.commit('appConfig/changeLoading', true);

      try {
        this.$store.commit('appConfig/changeStore', pro);
        
        this.prod.name = pro.name;
        this.prod.id_currency = pro.currency;
        this.prod.Terms_and_Conditions = pro.terms;
        this.prod.id = pro.id;
        this.$store.commit('appConfig/changeLoading', false);

      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);

        showProductNotFoundToast('danger');
      }
    },
    async getProyectos() {
      try {
        
      
      this.$store.commit('appConfig/changeLoading', true);

      const userLocalStorage = getUserData();

      const token = localStorage.getItem('token');

      const users = await axios({
        method: 'get',
        url: `${backendUrl}/usuarios/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = users.data.user;

      const usuario = await user.filter(
        (e) => e.id === userLocalStorage.idUser
      );

      const productosdb = await axios({
        method: 'get',
        url: `${backendUrl}/productos/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const productos = productosdb.data.project;

      for (let i = 0; i < usuario[0].project.length; i++) {
        const producto = await productos.filter(
          (e) => e.id === usuario[0].project[i].id_project
        );
        
        this.proyectos.push({
          name: producto[0].name,
          id: producto[0].id,
          id_currency: producto[0].id_currency,
          Terms_and_Conditions: producto[0].Terms_and_Conditions,
        });
        this.prod = {
          name: this.$store.state.appConfig.projecto.name,
          id: this.$store.state.appConfig.projecto.id,
          id_currency: this.$store.state.appConfig.projecto.id_currency,
          Terms_and_Conditions: this.$store.state.appConfig.projecto
            .Terms_and_Conditions,
          };
        }
        this.$store.commit('appConfig/changeLoading', false);

      } catch (error) {
        this.$store.commit('appConfig/changeLoading', false);
        return error
      }  


      
    },
  },
  data() {
    return {
      searchText:'',
      proyectos: [],
      prod: {
        id: '',
        name: '',
        id_currency: '',
        Terms_and_Conditions: '',
      },
    };
  },
  mounted() {
    this.getProyectos();
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'es', //TODO revisar este key
        img: require('@/assets/images/celcomLogo/divinoTarot.png'),
        name: 'Divino Tarot',
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style scoped>
.color-text-producto-dropdown {
  color: #323840 !important;
}

.drop-scrolling {
  overflow-y: scroll !important;
  height: 300px !important;
}
.search-proyect{
  width: 90%;
}
</style>
